<template><div class="problem">
	<div class="box"><div class="p16">
		<div class="pb16 flex jc_b ai_c">
			<div class="flex jc_s">
				<i class="iconfont iconzongji f16 lan"></i>
				<span class="col666 f14 ml10">共{{total}}条内容</span>
			</div>
			<div class="flex jc_end f14 col333 ai_c">
				<el-input v-model="valSerch" placeholder="请输入" style="width: 19.875rem;" class="xzinput"></el-input>
				<el-button type="primary" class="ssbtn" style="width: 5.375rem; margin: 0 20px;" @click="filter">搜 索</el-button>
				<el-button type="primary" class="kqbtn" style="width: 5.375rem;height: 32px!important;" @click="fb">发布</el-button>
			</div>
		</div><!--menub end-->
		<div class="table table2">
			<el-table :data="tableData" style="width: 100%">
				<el-table-column type="expand" width="90">
				  <template slot-scope="props">
					<el-form label-position="left" inline class="demo-table-expand">
					  <el-form-item >
						<span>{{ props.row.content }}</span>
					  </el-form-item>
					</el-form>
				  </template>
				</el-table-column>
				<el-table-column label="ID"  prop="id" width="370">
				  <template slot-scope="scope">
				  	<div>{{scope.row.id}}</div>
				   </template> 
				</el-table-column>
				<el-table-column label="标题"  prop="title" width="1060">
				  <template slot-scope="scope">
				  	<div>{{scope.row.title}}</div>
				   </template> 
				</el-table-column>
				<!--<el-table-column label="创建人"  prop="cjr" width="440">
				  <template slot-scope="scope">
				  	<div>{{scope.row.cjr}}</div>
				   </template> 
				</el-table-column>-->
				<el-table-column label="操作" width="145"><template slot-scope="scope">
				  <el-button class="qxbtn" style="margin-left: 10px;"  @click="bjfb(scope.$index,scope.row)">修改</el-button>
				  <el-button class="delbtn" @click="delMessage(scope.$index,scope.row)">删除</el-button>
				</template> </el-table-column>
			</el-table>
		</div><!--table end-->
		<div class="page pt16">
			<el-pagination
				 background
				 @size-change="handleSizeChange"
				  @current-change="handleCurrentChange"
				  :current-page="currentPage"
				  :page-sizes="[2, 5, 8, 10]"
				  :page-size="pageSize"
				  layout=" prev, pager, next"
				   :total="total">	
			</el-pagination>
		</div>
		
	</div></div>
</div></template>

<script>
export default {
    data(){
		return{
			valSerch:'',
			tableData:[	],
			pageSize:20,
			currentPage:1,
			total:0,
		}
	},//data
	mounted(){
		this.getData()
	},//
	methods:{
		getData(){
			const that=this
			let data={
				 limit: that.pageSize, 
				 offset: that.currentPage-1,
				 title:''
			}
			this.$api.getQuestion(data).then(res => {
			    that.total=res.data.data.count
				that.tableData=res.data.data.rows
				
			    console.log('getdata',res.data.data.rows)
			})
		},//getData
		filter(){
			const that=this
			let data={
				 limit: that.pageSize, 
				 offset: that.currentPage-1,
				 title:''
			}
			let list=[]
			this.$api.getQuestion(data).then(res => {
				if(that.valSerch){
					
					let list = res.data.data.rows.filter((item, index) =>item.title.indexOf(that.valSerch) !== -1)
					
					//let list=
					
					that.tableData = list
					that.total=list.length 
				}else{
				that.total=res.data.data.count
				that.tableData=res.data.data.rows
				 }		
			   
			})
			
		},//
		handleSizeChange(val) {
			this.pageSize=val
		      this.getData()
		      this.filter()
		
		      },
		  handleCurrentChange(val) {
			this.currentPage=val
			this.getData()
			this.filter()
		
		  },
		  bjfb(index,row){
			  this.$router.push({ path: '/problemEdit', query: { id: row.id,title:row.title,content:row.content} });
		  },//
		  fb(){
			this.$router.push('/problemEdit')  
		  },//
		  delMessage(index,row){
		  			  const that=this
		  			  this.$confirm('确定删除吗？', {
		  			            confirmButtonText: '确定',
		  			            cancelButtonText: '取消',
		  			            type: 'warning'
		  			          }).then(() => {
		  								   let data={  	"id": row.id	  }
		  								   
		  								   this.$api.deleteQuestion(data).then(res => {
		  									   console.log('del',res)
		  								      if(res.data.code==0){
		  										  that.$message.success(res.data.msg);
		  											
		  										}
		  									location.reload() 
		  								   })			   
		  			            
		  			          })
		  							  .catch(() => {
		  									 this.$message({
		  										 type: 'info',
		  										 message: '已取消删除'
		  									 });          
		  							  });
		  },//delMessage
	},//methods
}
</script>

<style lang="less" scoped>
    
</style>